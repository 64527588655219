import * as React from "react";
import { useIntl, navigate } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import * as UI from "../components/UI";
import { getUser, setUserData, saveUser } from "../redux/app/actions";
import l from "lodash";
import { Helmet } from "react-helmet";

const UserInfoPage = ({ bootLoaded, mayor, acepto, getUser, setUserData, saveUser, usuario, errors, s }) => {
	const intl = useIntl();

	const [canRender, setCanRender] = React.useState(true);

	React.useEffect(() => {
		if (mayor && acepto) setCanRender(true);
		else navigate("/");
	}, []);

	React.useEffect(() => {
		calcularPorcentaje();
	}, [usuario]);

	const initGetUser = (estanco) => {
		getUser(intl.locale, estanco.target.value);
	};

	const initSetUserData = (field, value) => {
		setUserData(field, value);
	};

	const save = () => {
		saveUser(intl.locale, usuario);
	};

	const [porcentaje, setPorcentaje] = React.useState(0);

	const calcularPorcentaje = () => {
		const fields = ["estanco", "direccion", "email", "nombre", "apellido", "telefono"];
		let completos = 0;
		fields.forEach((field) => {
			!l.isEmpty(usuario[field]) && completos++;
		});
		let porcentaje = 0;
		if (completos > 0) {
			porcentaje = Math.round(100 / (fields.length / completos));
		}
		setPorcentaje(porcentaje);
	};

	let eighteenYearsAgo = new Date();
	eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

	return (
		<UI.Layout narrowPadding={false} graficos="default" avance={porcentaje} background="1">
			{bootLoaded && canRender && (
				<>
					<Helmet>
						<title data-string="userinfoHTMLTitle">{s.userinfoHTMLTitle}</title>
					</Helmet>
					<UI.Separador />
					<UI.Parrafo data-string="userinfoRellena1 userinfoRellena2">
						<UI.Boldy>{s.userinfoRellena1}</UI.Boldy> {s.userinfoRellena2}
					</UI.Parrafo>
					<UI.Parrafo
						noBold={true}
						data-string="userinfoAdemas userinfoEntrarasAutomaticamente1 userinfoEntrarasAutomaticamente2 userinfoEntrarasAutomaticamente3"
					>
						{s.userinfoAdemas}{" "}
						<strong>
							{s.userinfoEntrarasAutomaticamente1} <UI.Boldy>{s.userinfoEntrarasAutomaticamente2}</UI.Boldy>
						</strong>{" "}
						{s.userinfoEntrarasAutomaticamente3}
					</UI.Parrafo>
					<UI.TextInput
						data-string="userinfoEstancoLabel"
						label={s.userinfoEstancoLabel}
						theme={
							l.includes(errors, "missing-estanco") || l.includes(errors, "wrong-estanco") ? "error" : "normal"
						}
						onBlur={initGetUser}
						value={usuario.estanco ? usuario.estanco : ""}
						onChange={(e) => initSetUserData("estanco", e.target.value)}
					/>
					<UI.TextInput
						data-string="userinfoNombreLabel"
						label={s.userinfoNombreLabel}
						theme={l.includes(errors, "missing-nombre") ? "error" : "normal"}
						value={usuario.nombre ? usuario.nombre : ""}
						onChange={(e) => initSetUserData("nombre", e.target.value)}
					/>
					<UI.TextInput
						data-string="userinfoApellidoLabel"
						label={s.userinfoApellidoLabel}
						theme={l.includes(errors, "missing-apellido") ? "error" : "normal"}
						value={usuario.apellido ? usuario.apellido : ""}
						onChange={(e) => initSetUserData("apellido", e.target.value)}
					/>
					<UI.TextInput
						data-string="userinfoDireccionLabel"
						label={s.userinfoDireccionLabel}
						theme={l.includes(errors, "missing-direccion") ? "error" : "normal"}
						value={usuario.direccion ? usuario.direccion : ""}
						onChange={(e) => initSetUserData("direccion", e.target.value)}
					/>
					<UI.TextInput
						data-string="userinfoEmailLabel"
						label={s.userinfoEmailLabel}
						theme={l.includes(errors, "missing-email") ? "error" : "normal"}
						value={usuario.email ? usuario.email : ""}
						onChange={(e) => initSetUserData("email", e.target.value)}
					/>
					<UI.TextInput
						data-string="userinfoTelefonoLabel"
						label={s.userinfoTelefonoLabel}
						theme={l.includes(errors, "missing-telefono") ? "error" : "normal"}
						value={usuario.telefono ? usuario.telefono : ""}
						onChange={(e) => initSetUserData("telefono", e.target.value)}
					/>
					<UI.Spacer size="10px" />
					<UI.Boton onClick={save} data-string="userinfoContinuar">
						{s.userinfoContinuar}
					</UI.Boton>
				</>
			)}
		</UI.Layout>
	);
};

const mapStateToProps = (state) => {
	const { bootLoaded, mayor, acepto, usuario } = state.app;
	const { userinfo: errors } = state.app.errors;
	const { strings: s } = state.app.boot;
	return {
		bootLoaded,
		mayor,
		acepto,
		usuario,
		errors,
		s,
	};
};

const mapDispatchToProps = {
	getUser,
	setUserData,
	saveUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoPage);
